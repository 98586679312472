<template>
  <v-dialog
    v-model="show"
    :fullscreen="isPhone"
    :width="dialogWidth"
    min-height="800"
    persistent
    overlay-opacity="0.97"
    @keydown.esc="cancel"
    eager
  >
    <v-card
      tile
      flat
      class="d-flex flex-column noscroll charcoal"
      v-touch:swipe.left="cancel"
    >
      <BaseCloseDialogHeader @cancel="cancel" heading="" class="" />

      <template>
        <v-hover v-slot="{ hover }" open-delay="150" close-deplay="200">
          <v-card
            class="mb-2 rounded-lg progressActive py-2 mx-2 charcoalTileMenu opacity-90"
            rounded
            :min-height="isPhone ? 0 : 110"
            href
            @click="startExisting"
            :elevation="hover ? 5 : 2"
          >
            <v-card-actions :class="textSize + ' black--text font-weight-bold  py-0'">
              START WORKOUT
              <v-spacer />
              <v-icon color="charcoal" large>
                mdi-weight-lifter
              </v-icon>
            </v-card-actions>
            <v-card-text class="charcoal--text px-2 py-0">
              Choose a workout from your workout list.
            </v-card-text>
          </v-card>
        </v-hover>
        <v-hover
          v-if="loaded"
          v-slot="{ hover }"
          open-delay="150"
          close-deplay="200"
        >
          <v-card
            class="mb-2 mx-2 rounded-lg progressActive py-2 charcoalTileMenu opacity-90"
            rounded
            :min-height="isPhone ? 0 : 100"
            href
            @click="startActivity"
            :elevation="hover ? 5 : 2"
          >
            <v-card-actions :class="textSize + ' black--text font-weight-bold py-0'">
              LOG ACTIVITY
              <v-spacer />
              <v-icon class="charcoal--text" large>
                mdi-run-fast
              </v-icon>
            </v-card-actions>
            <v-card-text class="charcoal--text px-2 py-0">
              Record your run, jog, or any other activity.
            </v-card-text>
          </v-card>
        </v-hover>
        <v-hover v-slot="{ hover }" open-delay="150" close-deplay="200">
          <v-card
            class="mb-2 rounded-lg charcoal py-2 mx-2 charcoalTileMenu"
            rounded
            :min-height="isPhone ? 0 : 110"
            href
            :elevation="hover ? 5 : 2"
            @click="viewList"
          >
            <v-card-actions :class="textSize + ' paper--text py-0'">
              WORKOUT OFFLINE
              <v-spacer />
              <v-icon :color="hasPacked?'paper':'silver'" large>
                mdi-wifi-strength-2-alert
              </v-icon>
            </v-card-actions>
            <template v-if="hasPacked">
              <v-card-text class="silver--text px-2 py-0">
                Use one of your "packed" workouts offline.
              </v-card-text>
              <v-card-actions class="pa-0 pr-2 mt-2">
                <v-spacer />
                <v-checkbox
                  dark
                  :retain-focus-on-click="false"
                  color="success"
                  off-icon="mdi-chevron-down-circle-outline"
                  on-icon="mdi-chevron-up-circle-outline"
                  v-model="showPackedDetails"
                  :label="showPackedDetails ? 'Hide' : 'Use Packed Workouts'"
                  title="Packed Workouts Details"
                  class="btn-fix ma-0 pa-0 mb-n4"
                />
              </v-card-actions>
            </template>
            <v-card-text v-else class="silver--text px-2 py-0">
              "Pack" a workout and it will be available offline.
            </v-card-text>
            <template v-if="showPackedDetails">
              <v-card-actions
                v-for="(workout, index) in packedWorkouts"
                :key="index"
                class=" charcoalTile py-2 mb-2 pr-0"
                @click="startPackedWorkout(workout)"
              >
                <span
                  :class="textSizeSmall + ' paper--text link'"
                  :title="'View Details for ' + workout.name"
                >
                  {{ workout.name }}
                </span>
                <v-spacer />
                <BaseActionButton
                  v-if="workout.starttime"
                  x-large
                  label="Resume"
                  title="Resume Workout"
                  text
                  color="purpleAccent"
                  icon="mdi-play-pause"
                />
                <BaseActionButton
                  v-else
                  x-large
                  label="Start"
                  title="Start Workout"
                  text
                  color="paper"
                  icon="  mdi-motion-play-outline"
                />
              </v-card-actions>
            </template>
          </v-card>
        </v-hover>
        <BaseLoading label="Loading Options" v-if="!loaded" />
        <template v-else>
          <v-card
            class="mb-2 rounded-lg charcoal py-2 mx-2 charcoalTileMenu"
            v-if="loaded && incomplete && incomplete.length > 0"
            rounded
          >
            <v-card-actions :class="textSize + ' silver--text py-0 pr-0'">
              Incomplete Workout
            </v-card-actions>
            <v-card-actions
              :class="textSizeSmall + ' py-0 progressActive--text'"
            >
              {{ lastIncomplete.name }}
            </v-card-actions>
            <v-card-actions class="pa-0 my-n3 pl-1">
              <v-checkbox
                dark
                :retain-focus-on-click="false"
                color="success"
                off-icon="mdi-chevron-down-circle-outline"
                on-icon="mdi-chevron-up-circle-outline"
                v-model="showIncompleteDetails"
                label="Details"
                title="Incomplete Workout Details"
                class="btn-fix"
              />
              <v-spacer />
              <BaseActionButton
                x-large
                label="Resume"
                title="Resume Workout"
                text
                color="purpleAccent"
                icon="mdi-play-pause"
                @clickedThis="startWorkout(lastIncomplete)"
              />
            </v-card-actions>
            <template v-if="showIncompleteDetails">
              <v-card-actions class="pr-1 py-0 silver--text">
                {{ lastIncomplete.description }}
              </v-card-actions>
              <v-card-actions>
                <v-list dense subheader class="transparent">
                  <v-list-item
                    v-for="exercise in lastIncomplete.exercises"
                    :key="exercise.id"
                    class="pl-1 py-0 "
                  >
                    <v-row dense>
                      <v-col cols="12">
                        <v-badge
                          color="success"
                          left
                          inline
                          :content="exercise.sort"
                        >
                          <div
                            :class="
                              textSizeXSmall +
                                ' ml-1 silver--text text--darken-1 link'
                            "
                            :title="'View Details for ' + exercise.name"
                            @click="showExercise(exercise.exerciseid)"
                          >
                            {{ exercise.name }}
                          </div>
                        </v-badge>
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-list>
              </v-card-actions>
            </template>
          </v-card>
          <v-card
            elevation="1"
            rounded
            class="mb-2 rounded-lg charcoal py-2 mx-2 charcoalTileMenu"
            v-if="loaded && lastWeek"
          >
            <v-card-actions :class="textSize + ' silver--text pb-0'">
              Last "{{ dayOfWeek() }}" Workout
            </v-card-actions>
            <v-card-actions
              :class="textSizeSmall + ' py-0 progressActive--text'"
            >
              {{ lastWeek.name }}
            </v-card-actions>
            <v-card-actions class="pa-0 pl-1 my-n3">
              <v-checkbox
                color="success"
                dark
                off-icon="mdi-chevron-down-circle-outline"
                on-icon="mdi-chevron-up-circle-outline"
                v-model="showLastDetails"
                label="Details"
                title="Last Week: Workout Details"
              />
              <v-spacer />
              <BaseActionButton
                x-large
                label="Repeat"
                :title="'Repeat' + lastWeek.name"
                text
                color="blueAccent"
                icon="mdi-repeat-variant"
                @clickedThis="startWorkout(lastWeek)"
              />
            </v-card-actions>
            <template v-if="showLastDetails">
              <v-card-actions class="pr-1 py-0 silver--text">
                {{ lastWeek.description }}
              </v-card-actions>
              <v-card-actions>
                <v-list dense subheader class="transparent">
                  <v-list-item
                    v-for="exercise in lastWeek.exercises"
                    :key="exercise.id"
                    class="pl-1 py-0"
                  >
                    <v-row dense>
                      <v-col cols="12">
                        <v-badge
                          color="progressActive charcoal--text"
                          left
                          inline
                          :content="exercise.sort"
                        >
                          <div
                            :class="
                              textSizeXSmall +
                                ' ml-1 silver--text text--darken-1 link'
                            "
                            :title="'View Details for ' + exercise.name"
                            @click="showExercise(exercise.exerciseid)"
                          >
                            {{ exercise.name }}
                          </div>
                        </v-badge>
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-list>
              </v-card-actions>
            </template>
          </v-card>
        </template>

        <v-spacer />
        <v-hover
          v-if="isPhone"
          v-slot="{ hover }"
          open-delay="150"
          close-deplay="200"
        >
          <v-card
            class="mb-2 mx-2 rounded-lg charcoal py-2 charcoalTile"
            rounded
            :min-height="isPhone ? 0 : 100"
            href
            flat
            :elevation="hover ? 5 : 2"
          >
            <v-sheet class="transparent" @click="manageQRScan()">
              <v-card-actions :class="textSize + ' paper--text pt-1 pb-0'">
                SCAN WORKOUT
                <v-spacer />
                <v-icon class="silver--text  " large>
                  mdi-qrcode
                </v-icon>
              </v-card-actions>
              <v-card-text class="silver--text px-2 py-0">
                Scan a workout's QR code from a friend.
              </v-card-text>
            </v-sheet>
            <template v-if="selectedWorkout != null">
              <v-card-text
                :class="textSize + ' paper--text px-2 pb-0 mt-2 text-right'"
              >
                {{ selectedWorkout.name }}
              </v-card-text>

              <v-card-actions class="py-0 px-0">
                <BaseActionButton
                  :large="!isPhone"
                  icon="mdi-pencil-box-multiple-outline"
                  :title="'Copy Workout: ' + workout.name"
                  label="Copy "
                  text
                  color="paper"
                  :class="textSize + ' transparent'"
                  @clickedThis="copyNow()"
                />
                <v-spacer />
                <BaseActionButton
                  :large="!isPhone"
                  icon="mdi-play-box-outline"
                  :title="'Start Workout: ' + selectedWorkout.name"
                  label="Start Workout "
                  text
                  color="charcoal"
                  :class="textSize + ' progressActive'"
                  :style="charcoalTileMenu"
                  @clickedThis="startWorkout(selectedWorkout)"
                />
              </v-card-actions>
            </template>
          </v-card>
        </v-hover>
        <template>
          <v-card
            rounded
            class="mb-2 rounded-lg charcoal py-0 mx-2 charcoalTileMenu"
            v-if="activeProgram"
          >
            <v-card-actions :class="textSize + ' paper--text pb-0'">
              ACTIVE PROGRAM
              <v-spacer />
              <v-icon color="progressActive">mdi-dots-circle</v-icon>
            </v-card-actions>
            <v-card-actions
              :class="textSize + ' py-0 gold--text'"
            >
              {{ activeProgram.name }}
            </v-card-actions>
            <v-card-actions class="pa-0 pl-1 my-n3">
              <v-checkbox
                dark
                color="success"
                off-icon="mdi-chevron-down-circle-outline"
                on-icon="mdi-chevron-up-circle-outline"
                v-model="showProgramDetails"
                label="Details"
                title="Details"
              />
              <v-spacer />
              <BaseActionButton
                x-large
                label="Continue"
                :title="'Continue ' + activeProgram.name"
                text
                class="paper--text"
                icon="mdi-motion-play"
                @clickedThis="goTo('/program/view/' + activeProgram.id)"
              />
            </v-card-actions>
            <template v-if="showProgramDetails">
              <v-card-actions class="pr-1 py-0 silver--text">
                {{ activeProgram.description }}
              </v-card-actions>
              <v-card-actions>
                <v-list dense subheader class="transparent">
                  <v-list-item
                    v-for="workout in activeProgram.workouts"
                    :key="workout.id"
                    class="pl-1 py-0"
                  >
                    <v-row dense>
                      <v-col cols="12">
                        <v-badge
                          color="progressActive charcoal--text"
                          left
                          inline
                          :content="workout.sort"
                        >
                          <div
                            :class="
                              textSizeXSmall +
                                ' ml-1 silver--text text--darken-1 link'
                            "
                            :title="'View Details for ' + workout.name"
                          >
                            {{ workout.name }}
                          </div>
                        </v-badge>
                      </v-col>
                    </v-row>
                  </v-list-item>
                </v-list>
              </v-card-actions>
            </template>
          </v-card>
          <v-card
            elevation="1"
            rounded
            class="charcoal paper--text ma-2 pa-1 link charcoalTile"
            v-else
          >
            <v-card-actions :class="textSize + ' silver--text pb-0'">
              You have no active programs!
            </v-card-actions>
            <v-card-actions :class="textSizeXSmall + ' py-0 silver--text'">
              Programs allow you organize your workouts. A program consists of
              multiple workouts normally executed in sequence. When you create a
              program, you never have to remember what workout you should do
              next.
            </v-card-actions>
            <v-card-actions class="pa-0 pl-1">
              <v-spacer />
              <BaseActionButton
                x-large
                label="Create Program"
                title="Create Program"
                text
                class="progressActive--text"
                icon="mdi-plus-circle-outline-outline"
                @clickedThis="goTo('/programs/add')"
              />
            </v-card-actions>
          </v-card>
        </template>
      </template>
    </v-card>
    <v-dialog
      v-model="showExerciseInfo"
      :width="dialogWidth"
      :fullscreen="isPhone"
      overlay-color="charcoal"
      overlay-opacity="0.97"
    >
      <ExerciseInfo
        :exerciseid="selectedid"
        @keydown.esc="cancelInfo"
        @cancel="cancelInfo"
        @swipe_off="cancelInfo"
      />
    </v-dialog>
  </v-dialog>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const ExerciseInfo = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/ExerciseInfo.vue')

export default {
  components: { ExerciseInfo },
  mixins: [util],
  props: {
    show: {
      type: Boolean
    },
    switchNum: {
      type: Number,
      default: 0
    },
    workoutInstanceId: {
      type: String
    }
  },
  data() {
    return {
      lastWeek: null,
      incomplete: null,
      lastIncomplete: null,
      activeProgram: null,
      packedWorkouts: null,
      hasPacked: false,
      showIncompleteDetails: false,
      showPackedDetails: false,
      showLastDetails: false,
      showProgramDetails: false,
      showExerciseInfo: false,
      selectedid: null,
      workout: null,
      message: '',
      selectedWorkout: null,
      loaded: false,
      api: null
    }
  },
  watch: {
    showExerciseInfo: function(value) {
      this.$store.dispatch('session/toggleModal', value)
    },
    switchNum: function() {
      this.reset()
    },
    show: function(val) {
      this.$store.dispatch('session/toggleModal', val)
      if (val) {
        this.reset()
        this.reload()
      } else this.reset()
    }
  },
  computed: {
    ...appConfig
  },

  beforeMount() {
    this.api = axios.create({
      baseURL: this.baseURL,
      timeout: this.reqTimeout
    })
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('workoutqrscanned', this.qrCodeScanned)
      window.addEventListener('message', this.receiveMessage)
    })
  },
  beforeDestroy() {
    window.removeEventListener('workoutqrscanned', this.qrCodeScanned)
    window.removeEventListener('message', this.receiveMessage)
  },
  methods: {
    receiveMessage(event) {
      if (event.data.type == 'CLOSE_MODAL' && event.data.key == 'UoMc92xqvA') {
        this.cancel()
      }
    },
    manageQRScan() {
      this.scanWorkoutQR()
    },
    qrCodeScanned(event) {
      if (
        event.detail.message == 'WORKOUT_SCANNED' &&
        event.detail.key == 'PGUxP8nexQ'
      ) {
        this.message = event.detail.messageText
        this.workout = event.detail.data
        this.loadWorkout()
      } else if (
        event.detail.message == 'WORKOUT_CANCELLED' &&
        event.detail.key == 'PGUxP8nexQ'
      )
        this.message = event.detail.messageText
      else null
    },
    async reload() {
      await this.loadActiveProgram()
      await this.loadLastWeek()
      await this.loadIncomplete()
      let packed = localStorage.getItem('workouts_packed')
      if (packed) {
        this.packedWorkouts = JSON.parse(packed)
        this.hasPacked = this.packedWorkouts.length > 0
      }
    },
    viewList() {
      if (!this.hasPacked) this.startExisting()
    },
    reset() {
      this.loaded = false
      this.lastWeek = null
      this.incomplete = null
      this.lastIncomplete = null
      this.activeProgram = null
      this.packedWorkouts = null
      this.selectedWorkout = null
      this.workout = ''
    },
    goTo(path) {
      if (this.$route.path != path) this.$router.push(path)
      this.cancel()
    },
    cancelInfo() {
      this.showExerciseInfo = false
    },
    showExercise(id) {
      this.selectedid = id
      this.showExerciseInfo = true
    },

    startPackedWorkout(workout) {
      localStorage.setItem('startWorkout', true)

      let path = `/workouts/packed/start/${workout.workoutid}`

      if (workout.type === 'Circuit') {
        path = `/workouts/start/hiit/${workout.workoutid}`
      } else if (workout.type === 'Hybrid') {
        const type = workout.xmode === 'time' ? 'rft' : workout.xmode
        path = `/workouts/start/${type}/${workout.workoutid}`
      }

      this.$router.push({ path })
      this.cancel()
    },
    startWorkout(workout) {
      localStorage.setItem('startWorkout', true)

      let path = `/workouts/start/${workout.workoutid}`

      if (workout.type === 'Circuit') {
        path = `/workouts/start/hiit/${workout.workoutid}`
      } else if (workout.type === 'Hybrid') {
        const type = workout.xmode === 'time' ? 'rft' : workout.xmode
        path = `/workouts/start/${type}/${workout.workoutid}`
      }

      this.$router.push({ path })
      this.cancel()
    },
    async copyNow() {
      try {
        const response = await axios.post(`${this.baseURL}/workout/copy`, {
          workoutid: this.selectedWorkout.workoutid,
          description: this.selectedWorkout.description,
          newname: this.selectedWorkout.name,
          now: this.now()
        })

        if (response.status === 200) {
          this.toast(`Workout ${this.selectedWorkout.name} was copied!`)
          this.selectedWorkout = null
          this.$emit('cancel')
          this.$router.push('/myworkouts')
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            { root: true }
          )
        }
      } catch (error) {
        this.$store.dispatch(
          'notification/addErrors',
          error.message,
          5000,
          true,
          { root: true }
        )
      }
    },
    async loadActiveProgram() {
      this.loaded = false

      try {
        const response = await this.api.get('/workouts/foractiveprogram', {
          timeout: this.reqTimeout
        })

        if (response.status === 200) {
          this.activeProgram = response.data.data

          if (this.activeProgram) {
            this.activeProgram.workouts = JSON.parse(
              this.activeProgram.workouts
            )
          }

          localStorage.setItem('program', JSON.stringify(this.activeProgram))
          this.loaded = true
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            { root: true }
          )
        }
      } catch {
        let localProgram = localStorage.getItem('program')

        if (localProgram != null) {
          this.activeProgram = JSON.parse(localProgram)
        }

        this.loaded = true
      }
    },
    async loadWorkout() {
      this.loaded = false
      const url = `${this.baseURL}/workout/${this.workout}`

      try {
        const response = await axios.get(url)

        if (response.status === 200) {
          this.selectedWorkout = response.data.data

          if (this.selectedWorkout) {
            this.selectedWorkout.exercises = JSON.parse(
              this.selectedWorkout.exercises
            )
          }

          this.loaded = true
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            { root: true }
          )
        }
      } catch (error) {
        // Handle the error appropriately, such as sending it to a logging service or displaying a message to the user
        this.$store.dispatch(
          'notification/addErrors',
          error.message,
          5000,
          true,
          { root: true }
        )
      }
    },
    async loadLastWeek() {
      const url = `${this.baseURL}/workouts/lastweek/thisday`

      try {
        const response = await axios.get(url)
        if (response.status === 200) {
          this.lastWeek = response.data.data
          if (this.lastWeek) {
            this.lastWeek.exercises = JSON.parse(this.lastWeek.exercises)
          }
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            { root: true }
          )
        }
      } catch (error) {
        // Handle the error appropriately
        this.$store.dispatch(
          'notification/addErrors',
          error.message,
          5000,
          true,
          { root: true }
        )
      }
    },
    async loadIncomplete() {
      const url = `${this.baseURL}/workouts/incomplete`

      try {
        const response = await axios.get(url)
        if (response.status === 200) {
          this.incomplete = response.data.data
          this.lastIncomplete = this.incomplete[0]

          if (!this.isEmpty(this.lastIncomplete)) {
            this.lastIncomplete.exercises = JSON.parse(
              this.lastIncomplete.exercises
            )
          }

          this.loaded = true
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            { root: true }
          )
        }
      } catch (error) {
        // Handle the error appropriately, such as sending it to a logging service or displaying a message to the user
        this.$store.dispatch(
          'notification/addErrors',
          error.message,
          5000,
          true,
          { root: true }
        )
      }
    },
    startExisting() {
      let route = '/myworkouts'
      if (this.$route.path != route) this.$router.push(route)

      this.cancel()
    },
    startActivity() {
      let route = '/activities'
      if (this.$route.path != route) this.$router.push(route)

      this.cancel()
    },
    addNew() {
      let route = '/workouts/add'
      if (this.$route.path != route) this.$router.push(route)
      this.cancel()
    },
    cancel() {
      this.reset()
      this.$emit('cancel')
    }
  }
}
</script>
